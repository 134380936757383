/* istanbul ignore file */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { connect } from 'react-redux';

import { IMapStateToProps } from 'app/shared/modules/Campaign/GAM/GamBanner/GamBanner.types';
import GamBannerWrapper from 'app/shared/modules/Campaign/GAM/GamBanner/GamBannerWrapper';
import { breakpointSelector } from 'app/shared/modules/globalEvents/selectors';
import { TBreakpoint } from 'app/types/style.types';

const mapStateToProps = (state: TRootState): IMapStateToProps => {
  let isBlocked = true;

  if (typeof window !== 'undefined') {
    try {
      const ucData = localStorage.getItem('ucData');

      if (ucData) {
        try {
          const parsedData = JSON.parse(ucData);

          isBlocked =
            parsedData?.consent?.services['7M0cXS2pQ']?.consent === false;
        } catch (error) {
          console.error('Error parsing ucData:', error);
          isBlocked = true;
        }
      }
    } catch (error) {
      console.error('Error accessing localStorage:', error);
      isBlocked = true;
    }
  }

  return {
    breakpoint: breakpointSelector(state) as TBreakpoint,
    isBlocked,
  };
};

export const ConnectedGamBanner = connect(mapStateToProps)(GamBannerWrapper);
