import { CAPTIVES } from 'app/shared/modules/FDL/constants';
import { CartileFinancingDisclaimer } from 'app/shared/modules/Cartile/CartileUIComponents/CartileAdditionalInfos/CartileFinancingDisclaimer/CartileFinancingDisclaimer';

export const getDataForCartileAdditionalInfosContainer = ({
  vehicle,
  isLoading,
  showFinancingOptions,
}) => {
  const financingDisclaimer =
    vehicle?.financingPrecalc?.result?.price?.monthlyInstallmentLegalText;
  if (showFinancingOptions && financingDisclaimer) {
    const captiveId = vehicle.financingPrecalc?.searchFilters?.captiveId;
    const isInitiallyExpanded = captiveId === CAPTIVES.MERCEDES[0];
    return {
      CartileAdditionalInfosChildren: CartileFinancingDisclaimer,
      CartileAdditionalInfosChildrenProps: {
        isLoading,
        financingDisclaimer,
        isInitiallyExpanded,
      },
    };
  }

  return {
    CartileAdditionalInfosChildren: null,
    CartileAdditionalInfosChildrenProps: {},
  };
};
