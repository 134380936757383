import { CartilePriceId3Default } from 'app/shared/modules/Cartile/CartileUIComponents/CartilePrice/CartilePriceContainers/CartilePriceId3Default';
import { calculateGeoDistance } from 'app/shared/utils/utils';
import { CartileD2CLocation } from 'app/shared/modules/Cartile/CartileUIComponents/CartileLocation/CartileD2CLocation/CartileD2CLocation';
import { CartileCityLocation } from 'app/shared/modules/Cartile/CartileUIComponents/CartileLocation/CartileCityLocation/CartileCityLocation';
import { CartilePriceDefault } from 'app/shared/modules/Cartile/CartileUIComponents/CartilePrice/CartilePriceContainers/CartilePriceDefault';
import { CartilePriceD2CDefault } from 'app/shared/modules/Cartile/CartileUIComponents/CartilePrice/CartilePriceContainers/CartilePriceD2CDefault';
import { CartilePriceMTLDefault } from 'app/shared/modules/Cartile/CartileUIComponents/CartilePrice/CartilePriceContainers/CartilePriceMTLDefault';
import { CartileDistanceLocation } from 'app/shared/modules/Cartile/CartileUIComponents/CartileLocation/CartileDistanceLocation/CartileDistanceLocation';
import { CartilePriceMTLDefaultReduced } from 'app/shared/modules/Cartile/CartileUIComponents/CartilePrice/CartilePriceContainers/CartileMTLDefaultReduced';
import { CartilePriceDefaultReduced } from 'app/shared/modules/Cartile/CartileUIComponents/CartilePrice/CartilePriceContainers/CartilePriceDefaultReduced';
import { getIsInstamotionVehicle } from 'app/marketplace/utils/getIsInstamotionVehicle';

export const getDataForCartileLocation = ({
  vehicle,
  isLoading,
  isD2CVehicle,
  isId3Vehicle,
  isHorizontal,
  currentLocation,
}) => {
  const {
    dealer: { location },
  } = vehicle;

  if (!location) {
    return {};
  }

  const { geoCoordinates, city } = location;

  if (isD2CVehicle || getIsInstamotionVehicle(vehicle?.dataSource)) {
    return {
      CartileContentFooterLocationChildren: CartileD2CLocation,
      CartileContentFooterLocationChildrenProps: {
        isLoading,
        hideLocation: true,
      },
    };
  }

  if (isId3Vehicle && isHorizontal) {
    return {
      CartileContentFooterLocationChildren: CartileD2CLocation,
      CartileContentFooterLocationChildrenProps: {
        isLoading,
        isId3Vehicle,
      },
    };
  }

  if (currentLocation && isHorizontal) {
    const { lat: lat2, lon: lon2 } = geoCoordinates;
    const { lat: lat1, lon: lon1 } = currentLocation;
    const distance = calculateGeoDistance({ lat1, lat2, lon1, lon2 });

    return {
      CartileContentFooterLocationChildren: CartileDistanceLocation,
      CartileContentFooterLocationChildrenProps: {
        city,
        distance,
        isLoading,
      },
    };
  }
  return {
    CartileContentFooterLocationChildren: CartileCityLocation,
    CartileContentFooterLocationChildrenProps: {
      city,
      isLoading,
    },
  };
};

export const getDataForCartilePrice = ({
  target,
  vehicle,
  isLoading,
  isD2CVehicle,
  isId3Vehicle,
  showFinancingOptions,
  getCartilePriceTracking,
  showLinkToPDPFinancingSection,
  hideDccVehicle,
}) => {
  const { price, reducedPrice, highestPrice, vatReclaimable, id } = vehicle;

  const monthlyRate =
    vehicle?.financingPrecalc?.searchFilters?.monthlyInstallment;

  const linkTo = `/vehicle/${id}/finanz`;
  const linkToId3 = `/vehicle/${id}`;
  const text = 'Monatsrate berechnen';
  const handleTracking = getCartilePriceTracking({
    id,
    target,
    isD2CVehicle,
    isId3Vehicle,
  });

  if (isD2CVehicle) {
    return {
      CartileContentFooterPriceChildren: CartilePriceD2CDefault,
      CartileContentFooterPriceChildrenProps: {
        price,
        linkTo,
        isLoading,
        monthlyRate: Number(monthlyRate),
        vatReclaimable,
        handleTracking,
        reducedPrice,
        highestPrice,
        isId3Vehicle,
      },
    };
  }

  if (isId3Vehicle) {
    return {
      CartileContentFooterPriceChildren: CartilePriceId3Default,
      CartileContentFooterPriceChildrenProps: {
        price,
        linkTo: linkToId3,
        target: '_self',
        isLoading,
        leasingRate: vehicle.leasingPrecalc.result.price,
        vatReclaimable,
        handleTracking,
        reducedPrice,
        highestPrice,
        isId3Vehicle,
      },
    };
  }

  if (reducedPrice && showFinancingOptions) {
    return {
      CartileContentFooterPriceChildren: CartilePriceMTLDefaultReduced,
      CartileContentFooterPriceChildrenProps: {
        text,
        price,
        linkTo,
        isLoading,
        monthlyRate,
        highestPrice,
        vatReclaimable,
        handleTracking,
        hideDccVehicle,
      },
    };
  }
  if (reducedPrice && !showFinancingOptions) {
    return {
      CartileContentFooterPriceChildren: CartilePriceDefaultReduced,
      CartileContentFooterPriceChildrenProps: {
        text,
        price,
        linkTo,
        target,
        isLoading,
        highestPrice,
        vatReclaimable,
        handleTracking,
        showLinkToPDPFinancingSection,
        hideDccVehicle,
      },
    };
  }
  if (!reducedPrice && showFinancingOptions) {
    return {
      CartileContentFooterPriceChildren: CartilePriceMTLDefault,
      CartileContentFooterPriceChildrenProps: {
        text,
        price,
        linkTo,
        target,
        isLoading,
        monthlyRate,
        vatReclaimable,
        handleTracking,
      },
    };
  }
  if (!reducedPrice && !showFinancingOptions) {
    return {
      CartileContentFooterPriceChildren: CartilePriceDefault,
      CartileContentFooterPriceChildrenProps: {
        text,
        price,
        linkTo,
        target,
        isLoading,
        vatReclaimable,
        handleTracking,
        showLinkToPDPFinancingSection,
        hideDccVehicle,
      },
    };
  }
};

export const getDataForCartileContentFooter = ({
  target,
  vehicle,
  isLoading,
  isD2CVehicle,
  isId3Vehicle,
  isHorizontal,
  currentLocation,
  showFinancingOptions,
  getCartilePriceTracking,
  showLinkToPDPFinancingSection,
  hideDccVehicle,
}) => {
  return [
    getDataForCartileLocation({
      vehicle,
      isLoading,
      isD2CVehicle,
      isId3Vehicle,
      isHorizontal,
      currentLocation,
    }),
    getDataForCartilePrice({
      target,
      vehicle,
      isLoading,
      isD2CVehicle,
      isId3Vehicle,
      showFinancingOptions,
      getCartilePriceTracking,
      showLinkToPDPFinancingSection,
      hideDccVehicle,
    }),
  ];
};
